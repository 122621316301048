var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('k-crud-page',{scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap my-4"},[_c('k-group-select',{attrs:{"items":_vm.orgTreeList,"label":"机构名称"},model:{value:(_vm.cruddataMX.conditions.organizationName),callback:function ($$v) {_vm.$set(_vm.cruddataMX.conditions, "organizationName", $$v)},expression:"cruddataMX.conditions.organizationName"}}),_c('k-select',{staticClass:"my-1",attrs:{"items":_vm.oprList,"label":"检测类型"},model:{value:(_vm.cruddataMX.conditions.operationType),callback:function ($$v) {_vm.$set(_vm.cruddataMX.conditions, "operationType", $$v)},expression:"cruddataMX.conditions.operationType"}}),_c('k-select',{staticClass:"my-1",attrs:{"items":_vm.resultList,"label":"检测结果"},model:{value:(_vm.cruddataMX.conditions.inspectorResult),callback:function ($$v) {_vm.$set(_vm.cruddataMX.conditions, "inspectorResult", $$v)},expression:"cruddataMX.conditions.inspectorResult"}}),_c('k-date-range-select',{attrs:{"label":"开始时间"}}),_c('div',{staticClass:"d-flex justify-end ml-auto"},[_c('k-primary-button',{staticClass:"my-1 mr-4",attrs:{"text":"检索","icon":"mdi-magnify"},on:{"click":function($event){_vm.crudConditionsMX().resetPage()}}}),_c('k-cancel-button',{staticClass:"my-1 mr-4",attrs:{"text":"重置","icon":"mdi-restore"},on:{"click":function($event){_vm.crudConditionsMX().reset()}}})],1)],1),_c('v-divider')]},proxy:true},{key:"resultlist",fn:function(){return [_c('k-crud-table',{attrs:{"headers":_vm.columns,"items":_vm.cruddataMX.dataList,"value":_vm.cruddataMX.pagination.current,"total":_vm.cruddataMX.pagination.total},on:{"pageChanged":function (page) {
            _vm.crudPaginationMX().change(page);
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('k-crud-dialog',{attrs:{"title":_vm.crud.title,"maxwidth":"700px","actionshow":_vm.actionShow,"text":"撤销"},on:{"save":function () {
                  _vm.updateStatus(_vm.cruddataMX.data, 0);
                },"close":function () {
                  _vm.crudActionMX().closeEditingPage(_vm.crud);
                },"revoke":function () {
                  _vm.updateStatus(_vm.cruddataMX.data, 1);
                }},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:_vm.crud.form.ref},[_c('div',{staticClass:"d-flex flex-wrap my-4"},[_c('div',[_c('div',[_vm._v("时间："+_vm._s(_vm.cruddataMX.data.violationTime))]),_c('div',[_vm._v("机构："+_vm._s(_vm.cruddataMX.data.organizationName))]),_c('div',[_vm._v("柜台："+_vm._s(_vm.cruddataMX.data.location))]),_c('div',[_vm._v("操作人:"+_vm._s(_vm.cruddataMX.data.oprPeople))]),_c('div',[_vm._v("结果："+_vm._s(_vm.cruddataMX.data.result))]),(_vm.cruddataMX.data.result !== '合规')?_c('div',[_vm._v(" 结果描述："+_vm._s(_vm.cruddataMX.data.violationContent)+" ")]):_vm._e()]),_c('div',{staticClass:"ml-4"},[_c('v-img',{staticClass:"grey darken-4",attrs:{"src":"https://picsum.photos/350/165?random","width":"320","height":"200"}})],1)])])]},proxy:true}]),model:{value:(_vm.crud.switch),callback:function ($$v) {_vm.$set(_vm.crud, "switch", $$v)},expression:"crud.switch"}})],1)]},proxy:true},{key:"actions",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"green"},on:{"click":function($event){_vm.crudActionMX().details(_vm.crud, item.uuid, _vm.onOpenEdit('02'))}}},[_vm._v(" 查看 ")])]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }